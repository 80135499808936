import React from "react";
import { Page } from "../components";
import { graphql } from "gatsby";

import { Hero, History, Rooms, Hosting } from "../components/Home";

const IndexPage = ({ data }) => {
  const rooms = data.allContentfulEstrutura.edges.map(({ node }) => ({
    id: node.id,
    title: node.titulo,
    text: node.resumo.childMarkdownRemark.html,
    link: "/teste",
    images: [node.capa],
  }));

  return (
    <Page backgroundColor="white">
      <Hero data={data.allContentfulBannerPginaInicial} />
      <History history={data.contentfulAOriggem} team={data.allContentfulNossaEquipe} />
      <Rooms slides={rooms} />
      <Hosting data={data.allContentfulHospedagem} />
      {/* <AboutUs data={data.allContentfulOQueFalamSobreNs} /> */}
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulBannerPginaInicial(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          titulo
          texto {
            childMarkdownRemark {
              html
            }
          }
          imagem {
            gatsbyImageData
          }
        }
      }
    }

    contentfulAOriggem {
      id
      titulo
      texto {
        id
        childMarkdownRemark {
          html
        }
      }
    }

    allContentfulNossaEquipe(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          titulo
          descricao {
            id
            childrenMarkdownRemark {
              html
            }
          }
          imagem {
            gatsbyImageData(layout: CONSTRAINED, width: 880, height: 1012, quality: 100)
            title
            description
          }
        }
      }
    }

    allContentfulEstrutura(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          titulo
          slug
          resumo {
            childMarkdownRemark {
              html
            }
          }
          capa {
            gatsbyImageData(layout: CONSTRAINED, width: 1140, height: 850)
            title
            description
          }
          galeria {
            gatsbyImageData(layout: CONSTRAINED, width: 1140, height: 850)
            description
            title
          }
        }
      }
    }
    allContentfulHospedagem(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          slug
          titulo
          galeria {
            gatsbyImageData
            title
            description
          }
        }
      }
    }
  }
`;

export default IndexPage;
