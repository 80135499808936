import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import useMatchMedia from "use-match-media-hook";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import media from "styled-media-query";

import { Title, Text, Bullets } from "../../../components";

const Section = styled.section`
  width: 100%;
  background: #27494a;
  padding: 230px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  overflow: hidden;
  position: relative;

  ${media.lessThan("medium")`
    padding: 60px 0;

    ${Title} {
      font-size: 50px;
    }
  `}

  ${Title}, ${Text} {
    color: white;
  }
`;

const Container = styled.main`
  width: 90%;
  max-width: 1204px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

const OurHistory = styled.div``;

const Block = styled.div`
  width: calc(50% - 50px);

  ${media.lessThan("medium")`
    width: 100%;

    ${Title} {
      font-size: 36px;
      margin-bottom: 26px !important;
    }
  `}
`;

const OurTeam = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 290px;
  justify-content: space-between;

  ${Title} {
    margin-bottom: 90px;
  }

  ${Text} {
    margin-left: auto;
  }

  ${media.lessThan("medium")`
    margin-top: 80px;
  `}
`;

const Slider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  position: relative;

  .gatsby-image-wrapper {
    min-width: 100%;
  }

  ${media.lessThan("medium")`
    margin-top: 60px;
  `}
`;

const Slide = styled(motion.div)`
  width: 100%;
  min-width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  position: absolute;

  :not(:last-of-type) {
    margin-right: 10px;
  }
`;

const Infos = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  text-decoration: none;
  transition: all 600ms ease;

  :hover {
    ${Title} {
      transform: translateY(-10px);
    }
  }

  ${Title} {
    margin-bottom: 69px;
    transition: all 600ms ease;
  }
`;

const ContentText = styled.div`
  width: calc(100% - 76px);
  margin-left: auto;

  ${Text} {
    margin-bottom: 58px;
  }

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const Texts = styled.div`
  max-width: 803px;
  margin-left: 80px;
  margin-top: 30px;

  & > * + * {
    margin-top: 30px;
  }

  p {
    color: white;
  }

  ${media.lessThan("medium")`
    margin-left: 0;
  `}
`;

const BulletsHolder = styled.div`
  margin-top: 68px;

  ${media.lessThan("medium")`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `}
`;

const BackgroundBox = styled.div`
  width: 100%;
  height: 100%;
  background: #0d1f1f;
  position: absolute;
  top: 15%;
  right: 20%;
  opacity: 0.2;

  ${media.lessThan("medium")`
    right: 0;
    top: 75vw;
  `}
`;

const History = ({ history, team }) => {
  const [currentTeam, setCurrentTeam] = useState(0);
  const [desktop] = useMatchMedia(["(min-width: 800px)"]);

  return (
    <Section>
      <Container>
        <OurHistory>
          <Title variant="h1" as="h2">
            {history.titulo}
          </Title>

          <Texts dangerouslySetInnerHTML={{ __html: history.texto.childMarkdownRemark.html }} />
        </OurHistory>

        <OurTeam>
          <Block>
            <Title variant="h2" as="h3">
              Nossa equipe
            </Title>

            <ContentText>
              <Text>
                Para garantir a excelência na qualidade de vida dos hóspedes a Origgem oferece profissionais capacitados para cada cuidado e necessidade em sua saúde, com uma equipe interdisciplinar totalmente focada e especializada em cada atendimento oferecido.
              </Text>

              {desktop && (
                <BulletsHolder>
                  <Bullets
                    total={team.edges.length}
                    active={currentTeam}
                    onChangeCurrent={(current) => setCurrentTeam(current)}
                  />
                </BulletsHolder>
              )}
            </ContentText>
          </Block>

          <Block>
            <Slider>
              {team.edges.map(({ node }, index) => (
                <Slide
                  key={node.id}
                  animate={{
                    position: index === currentTeam ? "relative" : "absolute",
                    opacity: index < currentTeam ? 0 : 1,
                    // visibility: index < currentTeam ? "hidden" : "visible",
                    pointerEvents: index < currentTeam ? "none" : "auto",
                    top: 0,
                    left:
                      index === currentTeam
                        ? 0
                        : index < currentTeam
                        ? "calc(-100% - 10px)"
                        : index > currentTeam+1 ? "calc(200% + 10px)" : "calc(100% + 10px)",
                    transition: { bounce: 0.2 },
                  }}
                >
                  <GatsbyImage
                    image={getImage(node.imagem.gatsbyImageData)}
                    alt={node.imagem.description}
                    title={node.imagem.title}
                    loading="eager"
                  />
                  <Infos as={Link} to="/">
                    <Title as="h3" variant="h3">
                      {node.titulo}
                    </Title>
                  </Infos>
                </Slide>
              ))}
            </Slider>

            {!desktop && (
              <BulletsHolder>
                <Bullets
                  total={team.edges.length}
                  active={currentTeam}
                  onChangeCurrent={(current) => setCurrentTeam(current)}
                />
              </BulletsHolder>
            )}
          </Block>
        </OurTeam>
      </Container>
      <BackgroundBox />
    </Section>
  );
};

export default History;
