import { Link } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Title } from "../../../components";
import media from "styled-media-query";

const Section = styled.section`
  width: 100%;
  background: white;
  z-index: 3;
  position: relative;
  padding-bottom: 120px;

  ${media.lessThan("medium")`
    padding-bottom: 30px;
  `}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  ${media.lessThan("medium")`
    &:first-of-type {
      & > div {
        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
          padding: 60px !important;

          ${Title} {
            font-size: 36px;
            text-align: center;
          }
        }
      }
    }

    &:nth-child(2) {
      & > div:nth-child(1) {
        & > a > div > div:nth-child(1) {
          order: 2;
          justify-content: flex-start !important;
          padding-left: 30px;
          padding-right: 0 !important;
          
          h4:after {
            left: unset !important;
            right: 110% !important;
          }
        }
        & > a > div > div:nth-child(2) {
          order: 1;
        }
      }
    }
  `}
`;
const Block = styled.div`
  width: 50%;

  :hover {
    img {
      transform: scale(1.06);
    }
  }

  ${media.lessThan("medium")`
    width: 100%;
    transform: translateX(0) !important;

    ${Title} { 
      font-size: 20px;
    }
  `}
`;
const InnerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  ${({ index }) => `
    h4 {
      position: relative;
      
      :after {
        content: "";
        width: ${index === 2 ? "200px" : "150px"};
        height: 2px;
        position: absolute;
        top: 10px;
        right: ${index === 1 ? "auto" : "calc(100% + 10px)"};
        left: ${index === 1 ? "calc(100% + 10px)" : "auto"};
        bottom: 0;
        margin: auto;
        background: #27494a;
      }
    }
  `}

  ${media.lessThan("medium")`
    position: relative;
    top: unset;
    left: unset;
    width: 50%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    transform: translateX(0) !important;
    overflow: hidden;
    justify-content: flex-end;
    padding-right: 30px;

    h4:after {
      right: unset !important;
      left: 110%;
      top: 3px;
      width: 50px;
    }
  `}
`;

const ImageBlock = styled.div`
  width: 50%;
  position: relative;
  display: flex;

  img {
    transition: all 900ms ease !important;
  }

  .gatsby-image-wrapper {
    min-height: 400px;
  }

  ${media.lessThan("medium")`
    width: 100%;
    flex-flow: row wrap;
    align-items: center;

    .gatsby-image-wrapper {
      width: 50%;
    }
  `}
`;

const Hosting = ({ data }) => {
  const first = data.edges[0];
  const second = data.edges[1];
  const third = data.edges[2];

  return (
    <Section>
      <Row>
        <Block>
          <Link to={`/hospedagem/${first.node.slug}`}>
            <ImageBlock style={{ marginLeft: "auto" }}>
              <InnerContent style={{ transform: "translateX(-100%)" }} index={1}>
                <Title variant="h3" as="h4" color="#27494A">
                  {first.node.titulo}
                </Title>
              </InnerContent>
              <GatsbyImage
                image={getImage(first.node.galeria[0].gatsbyImageData)}
                alt={first.node.galeria[0].description}
                title={first.node.galeria[0].title}
                loading="eager"
              />
            </ImageBlock>
          </Link>
        </Block>
        <Block style={{ backgroundColor: "#27494A", paddingTop: 100, paddingLeft: 100 }}>
          <Title as="h2" variant="h2" color="white">
            Hospedagens
          </Title>
        </Block>
      </Row>

      <Row>
        <Block>
          <Link to={`/hospedagem/${second.node.slug}`}>
            <ImageBlock>
              <InnerContent style={{ transform: "translateX(100%)" }} index={2}>
                <Title as="h4" variant="h3" color="#27494A">
                  {second.node.titulo}
                </Title>
              </InnerContent>
              <GatsbyImage
                image={getImage(second.node.galeria[0].gatsbyImageData)}
                alt={second.node.galeria[0].description}
                title={second.node.galeria[0].title}
              />
            </ImageBlock>
          </Link>
        </Block>

        <Block style={{ transform: "translateX(5%)" }}>
          <Link to={`/hospedagem/${third.node.slug}`}>
            <ImageBlock>
              <InnerContent style={{ transform: "translateX(90%)" }} index={3}>
                <Title as="h4" variant="h3" color="#27494A">
                  {third.node.titulo}
                </Title>
              </InnerContent>
              <GatsbyImage
                image={getImage(third.node.galeria[0].gatsbyImageData)}
                alt={third.node.galeria[0].description}
                title={third.node.galeria[0].title}
              />
            </ImageBlock>
          </Link>
        </Block>
      </Row>
    </Section>
  );
};

export default Hosting;
